import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Collapse } from "antd";
import { read, updateTour } from "../actions/hotel";
import { useSelector } from "react-redux";
import TourEditForm from "../components/forms/TourEditForm";
import TourPathEditForm from "../components/forms/TourPathEditForm";
import axios from "axios";
import SitePublicAttachmentForm from "../components/cards/SitePublicAttachmentForm";
import AttachModal from "../components/modals/AttachModal";
import TourImageEditForm from "../components/forms/TourImageEditForm";



const { Panel } = Collapse;


// add new data here

const EditSite = ({ match }) => {
  // redux
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;
  // state
  const [values, setValues] = useState({
    title: "",
    content: "",
    location: "",
    geoLoc: "",
    price: "",
    layout: "",

    distance: "",
    duration: "",
    features: "",
    isLandmark: "",
    isHistory: "",
    isNature: "",
    isWaterAct: "",
    isSustainable: "",
    isEasy: "",
    isModerate: "",
    isStrenuous: "",
    isShopping: "",
    hasRestrooms: "",
    hasRefreshments: "",
    route: ""
  });
  const [image, setImage] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [preview, setPreview] = useState(
    "https://via.placeholder.com/100x100.png?text=NO+IMAGE"
  );
  const [preview2, setPreview2] = useState(
    "https://via.placeholder.com/100x100.png?text=NO+IMAGE"
  );
  const [preview3, setPreview3] = useState(
    "https://via.placeholder.com/100x100.png?text=NO+IMAGE"
  );
  const [showModal, setShowModal] = useState(false);

  // destructuring variables from state
  const { title, 
      content, 
      price, 
      location, 
      geoLoc,
      layout, 
      distance,  
      duration,
      features,
      isLandmark,
      isHistory,
      isNature,
      isWaterAct,
      isSustainable,
      isEasy,
      isModerate,
      isStrenuous,
      isShopping,
      hasRestrooms,
      hasRefreshments,
      route,
      geoLocation
  } = values;

  let newDuration = -1;
  let newDistance = -1;

  useEffect(() => {
    loadSellerHotel();
    // eslint-disable-next-line
  }, []);

  const loadSellerHotel = async () => {
    let res = await read(match.params.hotelId);
    let troute = {jroute: JSON.parse(res.data.route)};
    // console.log(res);
    setValues({ ...values, ...res.data, ...troute });
    if (res.data.image)
      setPreview(`${process.env.REACT_APP_API}/tour/image/${res.data._id}`);
    if (res.data.image2)
      setPreview2(`${process.env.REACT_APP_API}/tour/image2/${res.data._id}`);
    if (res.data.image3)
      setPreview3(`${process.env.REACT_APP_API}/tour/image3/${res.data._id}`);
  };

  const handleSubmit = async (e) => {
    if (e)
      e.preventDefault();

    let newLocation = location;

    let siteData = new FormData();
    siteData.append("title", title);
    siteData.append("content", content);
    siteData.append("location", newLocation);
    siteData.append("geoLoc", geoLoc);
    siteData.append("price", price);
    image && siteData.append("image", image);
    image2 && siteData.append("image2", image2);
    image3 && siteData.append("image3", image3);
    siteData.append("layout", layout);

    siteData.append("distance", (newDistance >= 0) ? newDistance : distance);
    siteData.append("duration", (newDuration >= 0) ? newDuration : duration);
    siteData.append("features", features);
    siteData.append("isLandmark", isLandmark ? true: false);
    siteData.append("isHistory", isHistory ? true: false);
    siteData.append("isNature", isNature ? true: false);
    siteData.append("isWaterAct", isWaterAct ? true: false);
    siteData.append("isSustainable", isSustainable ? true: false);
    siteData.append("isEasy", isEasy ? true: false);
    siteData.append("isModerate", isModerate ? true: false);
    siteData.append("isStrenuous", isStrenuous ? true: false);
    siteData.append("isShopping", isShopping ? true: false);
    siteData.append("hasRestrooms", hasRestrooms ? true: false);
    siteData.append("hasRefreshments", hasRefreshments ? true: false);
    siteData.append("route", route);

    
    try {
      let res = await updateTour(token, siteData, match.params.hotelId);
      console.log("HOTEL UPDATE RES", res);
      toast.success(`${res.data.title} is updated`);
    } catch (err) {
      console.log(err); 
      console.log(err.response.data); 
      toast.error(err.response.data);
    }
  };

  const handleImageChange = (e) => {
    // console.log(e.target.files[0]);
    setPreview(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
    handleSubmit(null);
  };

  const handleImageChange2 = (e) => {
    // console.log(e.target.files[0]);
    setPreview2(URL.createObjectURL(e.target.files[0]));
    setImage2(e.target.files[0]);
    handleSubmit(null);
  };

  const handleImageChange3 = (e) => {
    // console.log(e.target.files[0]);
    setPreview3(URL.createObjectURL(e.target.files[0]));
    setImage3(e.target.files[0]);
    handleSubmit(null);
  };

  const handleChange = (e) => {
    if (e.target.name === "duration") {
      newDuration = e.target.value;
    }
    if (e.target.name === "distance") {
      newDistance = e.target.value;
    }
    if (e.target.name === "price" && e.target.value < -1) e.target.value = -1;
    console.log(`SetValues ${e.target.name} = ${e.target.value}`);
    setValues({ ...values, [e.target.name]: e.target.value });
  };


  return (
    <>
      <div style={{backgroundImage:"url(/backgrounds/editsite.jpg)", backgroundSize:"100% 110%"}} className="container-fluid bg-secondary p-5 text-center">
        <h2 className="page-title">Edit Walking Tour</h2>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-10">
          {showModal && (
                <AttachModal
                  id={values._id}
                  showModal={showModal}
                  setShowModal={setShowModal}
                />
                )}

            <br /><p>&nbsp;</p>
            <Collapse defaultActiveKey="1" accordion>
              <Panel header="Details" key="1">
                <TourEditForm
                  values={values}
                  setValues={setValues}
                  handleChange={handleChange}
                  handleImageChange={handleImageChange}
                  handleImageChange2={handleImageChange2}
                  handleImageChange3={handleImageChange3}
                  handleSubmit={handleSubmit}
                />
              </Panel>
              <Panel header="Promotional Images" key="2">
                <TourImageEditForm
                  values={values}
                  setValues={setValues}
                  handleChange={handleChange}
                  handleImageChange={handleImageChange}
                  handleImageChange2={handleImageChange2}
                  handleImageChange3={handleImageChange3}
                  handleSubmit={handleSubmit}
                  preview1={preview}
                  preview2={preview2}
                  preview3={preview3}
                />
              </Panel>
              <Panel header="Attachments" key="3">
                <p>Images displayed at Points of Interest along the path of your tour</p>
                <div className="card mb-3">
                  <div className="card-body">
                    <h3 className="card-title">
                      Attachments
                      <span width="20%" justify="right">
                        <label
                            onClick={() => setShowModal(!showModal)}
                            className="btn btn-outline-secondary btn-block m-2 text-left">
                          Attach Tour Image
                        </label>
                      </span>
                    </h3>
                    <SitePublicAttachmentForm key={values._id} allowEdit="1" h={values._id} ></SitePublicAttachmentForm>
                  </div>
                </div>
              </Panel>
              <Panel header="Path" key="4">
                <TourPathEditForm
                  values={values}
                  setValues={setValues}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  startPoint={[]}
                />
              </Panel>
            </Collapse>
          </div>
          <div className="col-md-2">
          { (false) ? (   <>
          <img
              src={preview}
              alt="preview_image"
              className="img img-fluid m-2"
            />
          <img
              src={preview2}
              alt="preview_image"
              className="img img-fluid m-2"
            />
          <img
              src={preview3}
              alt="preview_image"
              className="img img-fluid m-2"
            /> </>) : ""
          }
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSite;
