import { useState } from "react";
import { storageTypeString, durationStringMinutes, setQueueDownload } from "../../actions/hotel";
import { Image, Button, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { DownloadOutlined, InfoOutlined } from "@ant-design/icons";

const DownloadCard = ({ hotel, session, orderedBy, updatedAt, createdAt, queued }) => {
  const [curQueued, setCurQueued] = useState(queued);
  const { auth } = useSelector((state) => ({ ...state }));

  if (!hotel)
    return (<></>);
  
  return (
    <>
      <div className="card mb-3">
        <div className="row no-gutters">
        <div className="col-md-3">
          <p>
            {hotel.image && hotel.image.contentType ? (
              <Image
                src={`${process.env.REACT_APP_API}/tour/image/${hotel._id}`}
                alt="default site"
                className="card-image img img-fluid"
                style={{maxHeight:"150px", maxWidth:"150px"}}
                width="150px" 
              />
            ) : (
              <img
                src="https://via.placeholder.com/150x100.png?text=stashling.com"
                alt="default site"
                className="card-image img img-fluid"
              />
            )}
          </p>
        </div>
          <div className="col-md-6">
            <div className="card-body">
              <h5 className="card-title">
                {hotel.title}{" "}
              </h5>
              <p className="card-text">
                  This <span className="float-right text-primary"> {storageTypeString(hotel.layout)}{" "}
                </span>
                tour takes <span className="float-right text-primary">
                  {durationStringMinutes(hotel.duration)}
                </span> in <span className="float-right text-primary">{hotel.location}</span>
              </p>
            </div>            
          </div>
          <div className="col-md-3">

            <div >
                <Button style={{margin:"0.7em 0 0 0"}} type="primary" href={`/tour/${hotel._id}`}>Tour Details</Button>
                <p />
                <div className="d-flex justify-center h4">
                <Button type="primary" danger={curQueued} icon={<DownloadOutlined />} onClick={() => {setCurQueued((curQueued) ? false : true); setQueueDownload(auth.token, hotel._id)} }>
                  {!curQueued ? (<>Download to Device</>) : (<>Remove from Download</>)}
                </Button>
                <p>&nbsp;</p>
                <Tooltip title="Select the tours you want your phone to download for this trip. Tour data can be large, you may wish to download using Wi-Fi.">
                  <Button type="primary" size="small" shape="circle" icon={<InfoOutlined style={{position:"absolute", margin:"-0.7em 0 0 -0.5em"}}  />} />
                </Tooltip>
                </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadCard;
